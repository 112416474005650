












































































































































































































































import {
  apiShopLists,
  apiShopSwitchStatus,
  apiShopDelete,
  apiChangeRemark,
  apiAlipayVersionList,
  apiAlipayShopVersionAdd
} from '@/api/shop'

import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
import DatePicker from '@/components/date-picker.vue'
import { apiOtherLists } from '@/api/order'
@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
    DatePicker
  }
})
export default class ShopList extends Vue {
  /** S Data **/
  $refs!: { lsDialog: any;form:any}
  apiShopLists = apiShopLists;
  apiAlipayVersionList = apiAlipayVersionList;

  pager: RequestPaging = new RequestPaging();

  searchObj = {
    set_meal_id: '',
    set_meal_status: '',
    status: '',
    shop_info: '',
    start_time: '',
    end_time: '',
    time_type: ''
  };

  form: any = {}
  versionList: any = []
  otherLists: any = {
    set_meal: [],
    pay_status: [],
    pay_way: []
  };

  verId=0
  /** E Data **/

  /** S Methods **/
  // 获取订单其他 方式数据
  getOtherMethodList () {
    apiOtherLists().then((res: any) => {
      this.otherLists = res
    })
  }

  // 获取商城列表
  getShopLists (): void {
    this.pager.request({
      callback: apiShopLists,
      params: {
        ...this.searchObj
      }
    })
  }

  getShopVerList () {
    apiAlipayVersionList({
      page_size: 15,
      page_no: 1
    }).then(res => {
      console.log(res)
      this.versionList = res.lists
    })
  }

  // 修改商城状态
  async shopSwitchStatusEdit (status: number, id: number): Promise<void> {
    await apiShopSwitchStatus({ id })
    this.getShopLists()
  }

  async changeRemarks (text: string, id: number): Promise<void> {
    await apiChangeRemark({ id: id, remark: text })
    this.getShopLists()
  }

  handleSave () {
    this.form.sid = this.verId
    // if (!this.form.versionId) {
    //   return this.$message.warning('请选择版本号')
    // }
    this.$refs.form.validate((valid: boolean, object: any) => {
      if (valid) {
        apiAlipayShopVersionAdd(this.form).then(res => {
          if (res.code == 1) {
            this.form.versionId = ''
            this.getShopLists()
          }
        }).catch()
      }
    })
  }

  // 删除商城
  async onShopDelete (row: any): Promise<void> {
    await apiShopDelete({ id: row.id })
    this.getShopLists()
  }

  // 重置搜索
  reset (): void {
    Object.keys(this.searchObj).map((key) => {
      this.$set(this.searchObj, key, '')
    })
    this.getShopLists()
  }

  // 去编辑商城
  toShopEdit (id: number | any): void {
    this.$router.push({
      path: '/shop/lists_detail',
      query: {
        id: id
      }
    })
  }

  // 去支付宝授权
  toShopAuth (row: any): void {
    // const bizDataObj = {
    //   platformCode: 'O',
    //   taskType: 'INTERFACE_AUTH',
    //   agentOpParam: {
    //     redirectUri: row.redirectUri,
    //     appTypes: ['TINYAPP', 'WEBAPP', 'PUBLICAPP', 'MOBILEAPP'],
    //     isvAppId: row.isvAppId,
    //     state: row.id
    //   }
    // }
    // const bizData = encodeURIComponent(JSON.stringify(bizDataObj))
    const link = row.authUrl
    console.log(link, 'link')
    var el = document.createElement('a')
    document.body.appendChild(el)
    el.href = link // url 是你得到的连接
    el.target = '_new' // 指定在新窗口打开
    el.click()
    document.body.removeChild(el)
  }

  // 修改超级管理员
  toSuperAdminUpdate (id: number | any): void {
    this.$router.push({
      path: '/shop/modify_account',
      query: {
        id: id
      }
    })
  }

  // 去商家后台
  toShop (url: any, status: number) {
    if (status == 0) return this.$message.error('该商城暂未营业中')

    if (url.indexOf('https://') === 0) window.open(url)
    else if (url.indexOf('http://') === 0) window.open(url)
    else window.open('http://' + url)
  }

  /** E Methods **/

  /** S ods **/
  created () {
    this.getShopLists()
    this.getOtherMethodList()
    this.getShopVerList()
  }

  closeDialog () {
    this.$refs.lsDialog.close()
    this.form.versionId = ''
  }

  openDialog () {
    this.$refs.lsDialog.open()
  }

  showDilog (id: number) {
    this.verId = id
    this.openDialog()
  }
}
